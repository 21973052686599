import { Shoe } from "./interfaces";

export const shoes: Shoe[] = [
  {
    id:1,
    name:"J068010",
    brand:"Wolverine",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068010/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068010/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068010/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:4,
    date_created:1590499834,
    last_updated:1590499834
   },
  {
    id:2,
    name:"S2072020",
    brand:"Wolverine",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S20720-20/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S20720-20/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S20720-20/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:1,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:3,
    name:"S1081334",
    brand:"Wolverine",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S10813-34/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S10813-34/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S10813-34/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:2,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:4,
    name:"J068120",
    brand:"Wolverine",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068120/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068120/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068120/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:3,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:5,
    name:"J068010",
    brand:"Wolverine",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068010/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068010/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/J068010/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:4,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:6,
    name:"S2072020",
    brand:"Wolverine",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S20720-20/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S20720-20/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_wolverine_gs62vh72z/S20720-20/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:1,
    date_created:1590499834,
    last_updated:1590499834
   },
];
