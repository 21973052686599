export enum WindowMessage {
  Play = "VYKING_SNEAKER_WINDOW_PLAY",
  Pause = "VYKING_SNEAKER_WINDOW_PAUSE",
  TakePhoto = "VYKING_SNEAKER_WINDOW_TAKE_PHOTO",
  ReplaceAccessories = "VYKING_SNEAKER_WINDOW_REPLACE_ACCESSORIES",
  ExpiryTime = "VYKING_SNEAKER_WINDOW_EXPIRY_TIME",
  FeetDetected = "VYKING_SNEAKER_WINDOW_ARE_FEET_DETECTED",
  WindowReady = "VYKING_SNEAKER_WINDOW_READY",
  WaitingForConfig = "VYKING_SNEAKER_WINDOW_WAITING_FOR_CONFIG",
}
